const colors = {
  NEUTRALS: {
    WHITE: '#ffffff',
    ULTRA_LIGHT_GREY: '#EFEFEF',
    LIGHT_GREY: '#EAEAEA',
    GREY: '#C8C8C8',
    DARK_GREY: '#202020',
    BLACK: '#050703',
  },
  BLUE: {
    ULTRA_LIGHT_BLUE: '#EAEEF1',
    LIGHTER_BLUE: '#7BC6F0',
    LIGHT_BLUE: '#0077BD',
    BLUE: '#326BAC',
    DARK_BLUE: '#00488A',
    DARKER_BLUE: '#1F3363',
    ULTRA_DARK_BLUE: '#0B1742',
    ACCENT: '#3590C5',
  },
  PRIMARY: {
    SAND: '#B89259',
    GREEN: '#35C561',
    RED: '#FF414D',
    DARK_RED: '#B82D29',
  },
};

const COLOR = {
  ...colors,
  GRADIENTS: {
    DARK_BLUE: `linear-gradient(355deg, #000 0.98%, ${colors.BLUE.DARK_BLUE} 91.62%)`,
    BLUE: `linear-gradient(70deg, ${colors.BLUE.ULTRA_DARK_BLUE} 0%, ${colors.BLUE.DARK_BLUE} 50%, ${colors.BLUE.LIGHTER_BLUE} 100%)`,
    BLUE_SECONDARY: `linear-gradient(120deg, #1D3060 0%, #71A9DD 100%)`,
    BLUE_TERTIARY: `linear-gradient(355deg, #000 0.98%, ${colors.BLUE.DARK_BLUE} 91.62%)`,
    GOLD: `linear-gradient(70deg, #734E22 16.89%, #E3C274 73.3%)`,
    GOLD_SECONDARY: `linear-gradient(180deg, #734E22 16.89%, #E3C274 73.3%)`,
    GOLD_TERTIARY: `linear-gradient(166deg, #FFE297 16.89%, #D1AC73 73.3%)`,
    LIGHT_GOLD: `linear-gradient(94deg, #734E22 6.9%, #E3C274 93.1%)`,
    'GOLD-3': `linear-gradient(166deg, #FFE297 16.89%, #D1AC73 73.3%)`,
    SILVER: `linear-gradient(168deg, #F5F5F5 17.37%, #ADADAD 75.39%)`,
  },
};

export default COLOR;
