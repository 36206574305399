import { DefaultTheme as IDefaultTheme } from 'styled-components';
import FONT from 'src/core/theme/basic/font';
import COLOR from 'src/core/theme/basic/color';
import TYPOGRAPHY from 'src/core/theme/basic/typography';
import LAYOUT from 'src/core/theme/basic/layout';
import Z_INDEX from 'src/core/theme/z-index';
import TRANSITION from 'src/core/theme/transition';

const THEME: IDefaultTheme = {
  COLOR,
  FONT,
  TYPOGRAPHY,
  LAYOUT,
  Z_INDEX,
  TRANSITION,
};

export default THEME;
