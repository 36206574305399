'use client';

import { css, Typography } from 'styled-components';
import { HeadingTypes, ParagraphTypes } from '@app/core/theme/basic/const';

const HEADING_BASE = css`
  font-family: ${({ theme }) => theme.FONT.SECONDARY};
  font-weight: 400;
  font-style: normal;
`;

const HEADING_1 = css`
  ${HEADING_BASE};
  font-size: 4.8rem;
  line-height: 5.2rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 6rem;
    line-height: 6.4rem;
  }
`;

const HEADING_2 = css`
  ${HEADING_BASE};
  font-size: 4rem;
  line-height: 4.4rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 4.8rem;
    line-height: 5.6rem;
  }
`;

const HEADING_3 = css`
  ${HEADING_BASE};
  font-size: 3.6rem;
  line-height: 4rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 4.4rem;
    line-height: 4.8rem;
  }
`;

const HEADING_4 = css`
  ${HEADING_BASE};
  font-size: 2.8rem;
  line-height: 3.2rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 3.6rem;
    line-height: 4rem;
  }
`;

const HEADING_5 = css`
  ${HEADING_BASE};
  font-size: 2.4rem;
  line-height: 2.8rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }
`;

const HEADING_6 = css`
  ${HEADING_BASE};

  font-size: 2rem;
  line-height: 2.4rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`;

const BODY_BASE = css`
  font-family: ${({ theme }) => theme.FONT.PRIMARY};
  font-weight: 400;
  font-style: normal;
`;

const BODY_XS = css`
  ${BODY_BASE};
  font-size: 1.2rem;
  line-height: 1.8rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
`;

const BODY_XS_BOLD = css`
  ${BODY_XS};
  font-weight: 700;
`;

const BODY_XS_ITALIC = css`
  ${BODY_XS};
  font-style: italic;
`;

const BODY_SM = css`
  ${BODY_BASE};
  font-size: 1.4rem;
  line-height: 2.2rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

const BODY_SM_BOLD = css`
  ${BODY_SM};
  font-weight: 700;
`;

const BODY_MD = css`
  ${BODY_BASE};
  font-size: 1.6rem;
  line-height: 2.4rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
`;

const BODY_MD_BOLD = css`
  ${BODY_XS};
  font-weight: 700;
`;

const BODY_MD_BOLD_ITALIC = css`
  ${BODY_MD_BOLD};
  font-style: italic;
`;

const BODY_LG = css`
  ${BODY_BASE};
  font-size: 1.8rem;
  line-height: 2.8rem;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 2rem;
    line-height: 3.2rem;
  }
`;

const BODY_LG_BOLD = css`
  ${BODY_XS};
  font-weight: 700;
`;

const BODY_LG_BOLD_ITALIC = css`
  ${BODY_MD_BOLD};
  font-style: italic;
`;

const BODY_XL_BOLD = css`
  ${BODY_BASE};
  font-size: 2rem;
  line-height: 3.2rem;
  font-weight: 700;

  @media screen and (min-width: ${({ theme }) =>
      theme.LAYOUT.LAYOUT_BREAKPOINTS.MD}px) {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
`;

const typography: Typography = {
  [HeadingTypes.HEADING_1]: HEADING_1,
  [HeadingTypes.HEADING_2]: HEADING_2,
  [HeadingTypes.HEADING_3]: HEADING_3,
  [HeadingTypes.HEADING_4]: HEADING_4,
  [HeadingTypes.HEADING_5]: HEADING_5,
  [HeadingTypes.HEADING_6]: HEADING_6,
  [ParagraphTypes.BODY_XS]: BODY_XS,
  [ParagraphTypes.BODY_XS_BOLD]: BODY_XS_BOLD,
  [ParagraphTypes.BODY_XS_ITALIC]: BODY_XS_ITALIC,
  [ParagraphTypes.BODY_SM]: BODY_SM,
  [ParagraphTypes.BODY_SM_BOLD]: BODY_SM_BOLD,
  [ParagraphTypes.BODY_MD]: BODY_MD,
  [ParagraphTypes.BODY_MD_BOLD]: BODY_MD_BOLD,
  [ParagraphTypes.BODY_MD_BOLD_ITALIC]: BODY_MD_BOLD_ITALIC,
  [ParagraphTypes.BODY_LG]: BODY_LG,
  [ParagraphTypes.BODY_LG_BOLD]: BODY_LG_BOLD,
  [ParagraphTypes.BODY_LG_BOLD_ITALIC]: BODY_LG_BOLD_ITALIC,
  [ParagraphTypes.BODY_XL_BOLD]: BODY_XL_BOLD,
};

export default typography;
