'use client';

import StyledComponentsRegistry from '@app/core/components/styled-components';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, THEME } from 'src/core/theme';
import { IntlProvider } from 'react-intl';
import { ReactNode } from 'react';
import { UserProvider } from '@auth0/nextjs-auth0/client';

export default function RootProvider({
  dictionary,
  locale,
  children,
}: Readonly<{
  dictionary: Record<string, string>;
  locale: string;
  children: ReactNode;
}>) {
  return (
    <UserProvider>
      <StyledComponentsRegistry>
        <ThemeProvider theme={THEME}>
          <GlobalStyle />
          <IntlProvider
            messages={dictionary}
            locale={locale}
            defaultLocale="en-us"
          >
            {children}
          </IntlProvider>
        </ThemeProvider>
      </StyledComponentsRegistry>
    </UserProvider>
  );
}
