import { Racing_Sans_One, PT_Sans } from 'next/font/google';

const Racing = Racing_Sans_One({ weight: ['400'], subsets: ['latin'] });
const PT = PT_Sans({ weight: ['400', '700'], subsets: ['latin'] });

const FONT = {
  PRIMARY: PT.style.fontFamily,
  SECONDARY: Racing.style.fontFamily,
};

export default FONT;
