const LAYOUT_MAX_CONTAINER_WIDTH = 1600;
const LAYOUT_GRID_COLUMNS = 12;

const LAYOUT_BREAKPOINTS = {
  XXS: 0,
  XS: 375,
  SM: 640,
  MD: 1024,
  LG: 1280,
  XL: 1600,
};

const LAYOUT_GRID_GAP = {
  XXS: 16,
  XS: 16,
  SM: 24,
  MD: 24,
  LG: 24,
  XL: 32,
};

const LAYOUT_GRID_OFFSET = {
  XXS: 16,
  XS: 16,
  SM: 48,
  MD: 80,
  LG: 80,
  XL: 80,
};

const LAYOUT_EXTRA_WIDTH = {
  XXS: 16,
  XS: 16,
  SM: 48,
  MD: 80,
  LG: 80,
  XL: 80,
};

const LAYOUT = {
  LAYOUT_MAX_CONTAINER_WIDTH,
  LAYOUT_GRID_COLUMNS,
  LAYOUT_BREAKPOINTS,
  LAYOUT_GRID_GAP,
  LAYOUT_GRID_OFFSET,
  LAYOUT_EXTRA_WIDTH,
};

export default LAYOUT;
